import { Avatar, Divider, IconButton } from '@mui/material';
import { ReactComponent as LogoIcon } from '../../assets/svgs/Logo.svg';
import { ReactComponent as ChatIcon } from '../../assets/svgs/chat.svg';
import { ReactComponent as BlueChatIcon } from '../../assets/svgs/blueChat.svg';
import { ReactComponent as DropDown } from '../../assets/svgs/addDetail.svg';
import ASK_UNI from '../../assets/ASK_UNI.png';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import useStudentStore from '../../stores/StudentStore';
import { auth } from '../../firebase/config';
import { useEffect, useState, useTransition } from 'react';
import { useRef } from 'react';
import Menu from '../shared/Menu';
import MenuItem from '../shared/MenuItem';
import { useLocation, useNavigate } from 'react-router-dom';
import Tooltip from '../shared/Tooltip';
import { ReactComponent as ParentHeaderZeroState } from '../../assets/svgs/parentHeaderZeroState.svg';
import Button from '../shared/Button';
import AddIcon from '@mui/icons-material/Add';
import useThemeStore from '../../stores/ThemeStore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useMyStudentStore from '../../stores/MyStudentStore';
import { MY_COLLEGES } from '../../navigation/constants';
import { getActiveStudentReviewers } from '../../firebase/services/getServices';
import { useShallow } from 'zustand/react/shallow';
import { checkForPremiumAccess } from '../../utils/checkForPremiumAccess';
import useMyTeamStore from '../../stores/MyTeamStore';
import { getProfileByUserId } from '../../firebase/services/user';
import useTeamStore from '../../stores/TeamStore';
import useProfileStore from '../../stores/ProfileStore';
import { userTypeKeywords } from '../../constants/keywords';
import Heading from '../../pages/dashboardV2/Heading';
import { IoMdNotifications } from "react-icons/io";
import { NotificationPanel } from './NotificationPanel';
import BreakpointComponent from '../../hooks/useTailwindBreakpoint';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

const ROUTES = [
  {
    path: '/myHub',
    name: 'Hub',
  },
  {
    path: '/myStory',
    name: 'Story',
  },
  {
    path: '/myEssay',
    name: 'Essays',
  },
  {
    path: MY_COLLEGES,
    name: 'Colleges',
  },
];

export const Header = ({
  onMenuIconClick = () => {},
  onChatIconClick = () => {},
  onGenAIIconClick = () => {},
  isChatWindowOpen = false,
  isGenAIChatOpen = false,
  notification = 0,
  activitiesNotification = 0,
  isMobile = false,
}) => {

  const isSm = BreakpointComponent();

  const studentStore = useStudentStore(
    useShallow((state) => {
      return {
        selectedStudent: state.selectedStudent,
        setIsStudentSelect: state.setIsStudentSelect,
        setSelectedStudent: state.setSelectedStudent,
        setStudentId: state.setStudentId,
        setIsSideBarOptions: state.setIsSideBarOptions,
        student: state.student,
        setActiveStudentReviewers: state.setActiveStudentReviewers,
        activeStudentReviewers: state.activeStudentReviewers,
      };
    })
  );
  const teamStore = useTeamStore(
    useShallow((state) => ({
      teams: state.teams,
      selectedTeam: state.selectedTeam,
      setSelectedTeam: state.setSelectedTeam,
    }))
  );

  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  const [isPending, startTransition] = useTransition();
  const containerRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        startTransition(() => {
          setIsNotificationOpen(false);
        });
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleNotificationClick = (event) => {
    event.stopPropagation(); // Prevents the click from propagating to the outside click listener
    setIsNotificationOpen((prev) => !prev); // Toggles the panel
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isLocationBreadCrumbOpen, setIsLocationBreadCrumbOpen] =
    useState(false);
  const button = useRef();
  const locationCrumb = useRef();
  const {
    setIsMobileViewHeader,
    isMobileViewHeader,
    isHeaderTitle,
    setIsHeaderTitle,
    setIsMenuOpened,
    isMenuOpened
  } = useThemeStore();
  const navigate = useNavigate();
  const location = useLocation();
  const { setIsAddStudent } = useMyStudentStore();
  const profileStore = useProfileStore();

  const isParent =
    profileStore?.profile?.profileType !== userTypeKeywords.STUDENT;

  useEffect(() => {
    if (studentStore.selectedStudent?.uid) {
      async function getActiveStudentReviewer(studentId) {
        const activeStudentReviewers = await getActiveStudentReviewers(
          studentId
        );
        const registeredActiveStudentReviewers = activeStudentReviewers.filter(
          (reviewer) => reviewer.uid
        );

        studentStore?.setActiveStudentReviewers(
          registeredActiveStudentReviewers
        );
      }

      getActiveStudentReviewer(studentStore?.selectedStudent?.uid);
    }
  }, [studentStore?.selectedStudent]);

  useEffect(() => {
    if (location?.pathname === '/myHome') {
      studentStore?.setStudentId(null);
      studentStore?.setSelectedStudent(null);
    }
  }, [location?.pathname]);

  const onSelectTeam = async (student) => {
    // If the newly selected student is the same, do nothing
    if (student?.studentEmail === teamStore.selectedTeam?.studentEmail) {
      return;
    }
    setIsOpen(false);
    const collabProfiles = await Promise.all(
      student?.teamMembers?.map(async (member) => {
        let memberDetails = { ...member };
        if (member?.uid) {
          const memberProfile = await getProfileByUserId(member?.uid);
          const { photoURL, firstName, lastName } = memberProfile[0];
          memberDetails = {
            ...memberDetails,
            photoURL,
            firstName,
            lastName,
          };
        }
        return memberDetails;
      })
    );
    teamStore?.setSelectedTeam({
      ...student,
      teamMembers: collabProfiles,
    });
    studentStore?.setStudentId(student);
    studentStore.setIsSideBarOptions(true);
    navigate('/myHub');
    return;
  };
  const selectedTeam = teamStore?.selectedTeam;
  const selectedTeamStudentFirstName = selectedTeam?.studentFirstName;
  const selectedTeamStudentPhotoURL = selectedTeam?.studentPhotoURL;
  const selectedTeamStudentEmail = selectedTeam?.studentEmail;

  const getMyStoryBreadCrumb = (pathname) => {
    const pathNames = pathname.split('/');

    if (pathNames.includes('myStory')) {
      if(pathNames.includes('niche')) {
        return ['Passion', 'Niche']
      } else if(pathNames.includes('activities')) {
        return ['Passion', 'Activities']
      } else if(pathNames.includes('honors')) {
        return ['Passion', 'Honors']
      } else if(pathNames.includes('courses')) {
        return ['Passion', 'Courses']
      } else if(pathNames.includes('tests')) {
        return ['Passion', 'Tests']
      } else if(pathNames.includes('gpaScores')) {
        return ['Passion', 'GPA Rank']
      }
    }
    return ['Passion'];
  };
  return (
    <>
      {!isMobileViewHeader ? (
        <div ref={containerRef} className=" h-max sm:h-16 px-5 sm:px-8 bg-white flex sm:items-center sm:justify-between shadow z-30 shrink-0  sticky top-0  flex-col sm:flex-row py-3 sm:py-0 gap-6 sm:gap-0">
          <div className="flex items-center justify-between sm:flex-1">
            <div className="flex-1 flex items-center gap-4">
              {/* {isSm ?  */}
                <div
                className="cursor-pointer"
                onClick={() => {
                  setIsMenuOpened(!isMenuOpened);
                }}
              >
                <MenuRoundedIcon />
              </div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  if (isParent) {
                    navigate('/myHome');
                  } else {
                    if (selectedTeam) {
                      navigate(`${selectedTeam?.id}/myHub`);
                    }
                  }
                }}
              >
                <LogoIcon />
              </div>
              {/* <ArrowForwardIosIcon
                fontSize="small"
                className="!hidden sm:!flex"
              /> */}

              {(selectedTeamStudentEmail && !isSm) && (
                <div
                  className="flex items-center gap-3 p-3 rounded-lg text-sm"
                  ref={locationCrumb}
                >
                  {!location.pathname.includes('/myHub') && <span>/</span>}
                  <div>
                    {location.pathname === '/myHub' && 'Hub'}
                    {(location.pathname === '/myStory' ||
                      location.pathname.includes('/myStory')) &&
                        <div className='w-full flex items-center gap-3'>
                          {getMyStoryBreadCrumb(location.pathname).map((crumb, index) => (
                            <>
                              <div key={index} className='flex items-center gap-1 w-max'>
                                <span>{crumb}</span>
                              </div>
                              {index !== getMyStoryBreadCrumb(location.pathname).length - 1 && <span>/</span>}
                            </>
                          ))}
                        </div>
                      }
                    {(location.pathname === '/myEssay' ||
                      location.pathname.includes('/myEssay'))  && 'Essays'}
                    {(location.pathname === MY_COLLEGES ||
                      location.pathname.includes(MY_COLLEGES)) && 'Colleges'}
                    {location.pathname === '/myAccount/account' && 'Account'}
                    {location.pathname === '/referAFriend' && 'Refer A Friend'}
                  </div>
                </div>
              )}
            </div>
            {!isSm && location.pathname.includes('/myHub') && 
              <div className='flex-1'>
                <Heading/>
              </div>
            }
            <div className="relative flex items-center gap-4">
              <Tooltip
                title={
                  isChatWindowOpen ? 'Click to close Chat' : 'Chat with Uni'
                }
                placement="top-start"
              >
                {isSm ? <div className='rounded-full flex items-center justify-center w-7 h-7 overflow-hidden bg-[#FECA65]' role='button' onClick={onGenAIIconClick}>
                    <img src={ASK_UNI} alt="" className="bg-cover -mb-0.5 h-6" />
                  </div> :<Button
                  component="label"
                  variant="text"
                  className="sm:rounded-lg rounded-full !bg-[#FECA65]"
                  onClick={onGenAIIconClick}
                >
                  <div className="text-primary px-3 font-bold text-md flex items-center justify-center gap-2">
                    <img src={ASK_UNI} alt="" className="bg-cover -mb-1.5" />
                    <div className="text-sm font-medium text-[#674935]">
                      Ask Uni
                    </div>
                  </div>
                </Button>}
              </Tooltip>
              <Tooltip
                title={
                  isChatWindowOpen
                    ? 'Click to close Chat'
                    : 'Chat with the Student'
                }
                placement="top-start"
              >
                <IconButton
                  onClick={onChatIconClick}
                  className="parent-second-step"
                >
                  <div className='relative'>
                    {isChatWindowOpen ? (
                      <BlueChatIcon id="open-chat-icon" />
                    ) : (
                      <ChatIcon
                        id="close-chat-icon"
                        style={{
                          fill: '#838489',
                        }}
                      />
                    )}
                    {notification !== 0 && (
                      <div className="absolute h-[16px] w-[16px] bg-success-90 rounded-full border border-white flex items-center justify-center -top-[0.750rem] -right-[0.375rem] z-10">
                        <span className="text-[10px] font-medium text-white mt-px">
                          {notification}
                        </span>
                      </div>
                    )}
                  </div>
                </IconButton>
              </Tooltip>
              {!isSm && <Divider 
                orientation="vertical" 
                flexItem
                variant='middle'
                sx={{
                  height: '15px',
                  alignSelf: 'center',
                }}
              />}
              <Tooltip
                title="Notifications"
                placement="top-start"
              >
                <IconButton
                  onClick={handleNotificationClick}
                  className="parent-second-step"
                >
                  <div className='relative'>
                    {isNotificationOpen ? (
                      <IoMdNotifications className='text-brand-blue-500'/>
                    ) : (
                      <IoMdNotifications/>
                    )}
                    {activitiesNotification !== 0 && (
                      <div className="absolute h-[16px] w-[16px] bg-success-90 rounded-full border border-white flex items-center justify-center -top-[0.5rem] -right-[0.375rem] z-10">
                        <span className="text-[10px] font-medium text-white mt-px">
                          {activitiesNotification}
                        </span>
                      </div>
                    )}
                  </div>
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div className="justify-between items-center hidden">
            <div className="flex gap-4 items-center">
              <ArrowForwardIosIcon fontSize="small" />
              <div className="flex items-center gap-3">
                <div className="border border-grey-100 sm:hidden flex items-center gap-2 p-2 sm:p-3 rounded-lg">
                  {teamStore?.selectedTeam && (
                    <Avatar
                      sx={{ width: 24, height: 24 }}
                      src={
                        selectedTeamStudentPhotoURL
                          ? selectedTeamStudentPhotoURL
                          : selectedTeamStudentFirstName?.charAt(0)
                      }
                    />
                  )}
                  {selectedTeamStudentEmail && (
                    <div className="truncate text-sm">
                      {teamStore?.selectedTeam?.teamName || ''}
                    </div>
                  )}
                  {auth?.currentUser?.uid && !selectedTeamStudentEmail && (
                    <div className="truncate text-sm">All Students</div>
                  )}
                  <div className="flex items-center gap-2">
                    <Divider
                      flexItem
                      sx={{
                        borderColor: '#D6D6D8',
                      }}
                      orientation="vertical"
                    />
                    <IconButton
                      onClick={() => {
                        setIsOpen(true);
                      }}
                    >
                      <DropDown
                        className={`${
                          isOpen && 'rotate-180 transition duration-200 ease-in'
                        }`}
                      />
                    </IconButton>
                  </div>
                </div>
              </div>
            </div>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                setIsAddStudent(true);
              }}
            >
              Invite student
            </Button>
          </div>
        </div>
      ) : (
        <div className="h-fit bg-white gap-4 shadow z-50 shrink-0 sticky top-0">
          <div className="flex gap-4 p-4 items-center">
            <IconButton
              onClick={() => {
                setIsMobileViewHeader(false);
                setIsHeaderTitle('');
              }}
            >
              <ArrowBackIcon fontSize="small" />
            </IconButton>
            <div className="text-xl font-medium text-primary-black">
              {isHeaderTitle}
            </div>
          </div>
        </div>
      )}

      {/* Current location breadcrumb menu */}
      <Menu
        open={isLocationBreadCrumbOpen}
        anchorEl={locationCrumb.current}
        onClose={() => setIsLocationBreadCrumbOpen(false)}
        getContentAnchorEl={null}
      >
        {teamStore?.teams.filter((student) =>
          checkForPremiumAccess(student?.licenseData?.subscriptionStatus)
        ).length === 0 && (
          <div className="flex flex-col items-center text-center justify-center py-2 px-2">
            <ParentHeaderZeroState />
            <div className="text-grey-200 text-sm ">
              You can view and toggle between <br />
              students profiles once you <br />
              are added as a Team Member.
            </div>
          </div>
        )}
        {/* Map the student in the dropdown list */}
        {teamStore.teams
          .filter((student) =>
            checkForPremiumAccess(student?.licenseData?.subscriptionStatus)
          )
          .map((student) => (
            <MenuItem
              key={student.studentEmail} // Ensure a unique key here
              onClick={() => onSelectTeam(student)}
            >
              <div className="flex items-center gap-2">
                <Avatar
                  sx={{ width: 24, height: 24 }}
                  src={student?.studentPhotoURL}
                />
                <div>{student.teamName}</div>
              </div>
            </MenuItem>
          ))}

        {/* Map the routes in the dropdown list */}
        {ROUTES?.map((route) => (
          <MenuItem
            key={route.path} // Ensure a unique key here
            onClick={() => {
              setIsLocationBreadCrumbOpen(false);
              navigate(route?.path);
              return;
            }}
          >
            <div className="flex items-center gap-2">
              <div>{route?.name}</div>
            </div>
          </MenuItem>
        ))}
      </Menu>
      <div className="absolute right-3">
        <NotificationPanel 
          containerRef={containerRef}
          isVisible={isNotificationOpen} 
          onClose={() => setIsNotificationOpen(false)} 
        />
      </div>
    </>
  );
};
