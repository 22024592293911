import React, { useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Footer, PageViewTracker } from '../../components';
import ChatWindow from '../../components/chat/ChatWindow';
import useChatStore from '../../stores/ChatStore';
import useProfileStore from '../../stores/ProfileStore';

import { userTypeKeywords } from '../../constants/keywords';
import { ASK_UNI_MAIL } from '../../constants/staticData';
import useThemeStore from '../../stores/ThemeStore';
import useMyTeamStore from '../../stores/MyTeamStore';
import { getProfileDataByEmail } from '../../firebase/services/user';
import { addStoryItem } from '../../firebase/services/addServices';
import { addStoryItemTypes } from '../../constants/other';
import { Sidebar } from '../../components/Sidebar/Sidebar';
import { Header } from '../../components/Header/Header';
import useNotificationStore from '../../stores/NotificationStore';
import BreakpointComponent from '../../hooks/useTailwindBreakpoint';

const drawerWidth = 240;
const miniDrawerWidth = 80;

const DashBoardLayout = () => {
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const isSm = BreakpointComponent()
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const containerRef = useRef();
  const chatStore = useChatStore();
  const profileStore = useProfileStore();
  const myTeamStore = useMyTeamStore();
  const {notifications} = useNotificationStore()
  const isParent =
    profileStore?.profile?.profileType === userTypeKeywords.FAMILY ||
    profileStore?.profile?.profileType === userTypeKeywords.PRIVATE_COUNSELOR ||
    profileStore?.profile?.profileType === userTypeKeywords.SCHOOL_COUNSELOR ||
    profileStore?.profile?.profileType === userTypeKeywords.TEACHER ||
    profileStore?.profile?.profileType === userTypeKeywords.UNIVERSILY;
  const { isMobile, setIsMenuOpened, isMenuOpened } = useThemeStore();

  useEffect(() => {
    if (chatStore.isChatWindowOpen) {
      setHeight(
        isMobile
          ? containerRef.current.clientHeight
          : containerRef.current.clientHeight - 64
      );
      setWidth(isMobile ? containerRef?.current?.clientWidth : 400);
    } else {
      setHeight(0);
      setWidth(0);
    }
  }, [chatStore.isChatWindowOpen, isMobile]);

  const [totalUnreadCount, setTotalUnreadCount] = useState(0);

  const findCount = (arr) => {
    let count = 0;
    arr.forEach((chat) => {
      count += chat.unreadMessages;
    });
    return count;
  };
  
  useEffect(() => {
    const groupChatUnread = findCount(chatStore.groupChatRooms);
    const dmUnread = findCount(chatStore.chatRoomsDM);
    setTotalUnreadCount(dmUnread + groupChatUnread);
  }, [chatStore.chatRoomsDM, chatStore.groupChatRooms]);

  const [recentActivityNotificationUnreadCount, setRecentActivityNotificationUnreadCount] = useState(0);


  useEffect(() => {
    const unseenNotifications = notifications?.filter((notification) => !notification?.seenBy?.includes(profileStore.profile?.uid));
    setRecentActivityNotificationUnreadCount(unseenNotifications.length);
  }, [notifications])

  const handleAskUniChatClick = async () => {
    if (chatStore.isGenAIChatOpen) {
      chatStore.setIsChatOpen(false);
      chatStore.setOpenChatId(null);
      chatStore.setOpenChatType(null);
      chatStore.setUtilityId(null);
      chatStore.setIsGenAIChatOpen(false);
      chatStore.setIsChatWindowOpen(false);
      chatStore.setIsFilterTopic(false);
    } else {
      const chatTopic = chatStore.chatRoomsDM.find(
        (chatDM) => chatDM.email === ASK_UNI_MAIL
      );
      if (chatTopic?.chatParticipants) {
        chatStore.setOpenChatId(chatTopic.id);
        chatStore.setOpenChatType('directMessage');
        chatStore.setIsChatWindowOpen(true);
      } else {
        const teamUniversily = myTeamStore.myTeam.find(
          (teamMember) => teamMember.email === ASK_UNI_MAIL
        );
        if (teamUniversily) {
          const reviewer = await getProfileDataByEmail(ASK_UNI_MAIL);
          const response = await addStoryItem(
            {
              isTopic: false,
              archivedBy: [],
              chatParticipants: [reviewer?.uid, profileStore.profile?.uid],
            },
            addStoryItemTypes.CHAT_ROOM
          );
          if (response) {
            chatStore.setOpenChatType('directMessage');
            chatStore.setOpenChatId(response);
            chatStore.setIsChatWindowOpen(true);
          }
        }
      }
      chatStore.setIsGenAIChatOpen(true);
      chatStore.setIsChatWindowOpen(true);
    }
  };

  function setIsMenuOpen() {
    setIsMenuOpened(!isSideBarOpen);
  }

  return (
    <div className="h-full flex overflow-hidden flex-col" ref={containerRef}>
      <PageViewTracker />
      {/* Header */}
      <div
        style={{
          transition: 'margin-left 0.3s',
        }}
      >
        <Header
          onMenuIconClick={() => setIsSideBarOpen(!isSideBarOpen)}
          isChatWindowOpen={chatStore.isChatWindowOpen}
          onChatIconClick={() => {
            chatStore.setIsChatWindowOpen(!chatStore.isChatWindowOpen);
          }}
          notification={totalUnreadCount}
          activitiesNotification={recentActivityNotificationUnreadCount}
          isMobile={isMobile}
          onGenAIIconClick={handleAskUniChatClick}
          isGenAIChatOpen={chatStore.isGenAIChatOpen}
        />
      </div>
      <ChatWindow
        height={height}
        setOpenChatWindow={(value) => chatStore.setIsChatWindowOpen(value)}
        openChatWindow={chatStore.isChatWindowOpen}
        isParent={isParent}
        width={width}
      />

      {/* SideBar, same for both roles parent and student*/}
      {!isSm ? <Sidebar
        setIsSideBarOpen={setIsSideBarOpen}
        isSideBarOpen={isSideBarOpen}
      /> : (
        isMenuOpened &&
        <Sidebar
          setIsSideBarOpen={setIsMenuOpen}
          isSideBarOpen={isMenuOpened}
        />
      )}

      <div
        className="flex-1 flex h-full flex-col !overflow-y-auto"
        style={{
          marginLeft: isSm ? '' : isSideBarOpen ? drawerWidth : miniDrawerWidth,
          transition: !isSm && 'margin-left 0.3s',
        }}
      >
        {/* Dynamic Component */}
        <Outlet />
      </div>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default DashBoardLayout;
